<template>
  <section>
    <b-overlay :show="loading">
      <b-card class="mb-2">
        <section
          class="d-flex justify-content-start align-items-center"
          style="overflow: auto"
        >
          <div
            v-for="(paket, i) in dataPaket"
            :key="i"
            style="margin: 10px; min-width: 150px"
            class="d-flex justify-content-center"
          >
            <b-button
              size="sm"
              :active="paket.active"
              :variant="paket.active ? 'outline-danger' : 'link'"
              :style="
                paket.active
                  ? 'background-color: #FFF5E0;padding-top: 10px;padding-bottom: 10px;'
                  : 'padding-top: 10px;padding-bottom: 10px;'
              "
              @click.prevent="changeFilterPaket(paket)"
            >
              <strong :class="paket.active ? 'text-primary' : 'text-dark'">{{
                paket.name
              }}</strong>
            </b-button>
          </div>
        </section>
      </b-card>
      <b-overlay :show="loadingJadwal">
        <header
          v-if="selectedPaket"
          class="mb-2 d-flex justify-content-between align-items-center"
        >
          <div>
            <strong class="d-block"
              >Silahkan pilih jadwal yang sudah diselesaikan</strong
            >
          </div>
          <div>
            <b-form-select
              :options="jadwalOptions"
              v-model="selectedJadwal"
            ></b-form-select>
          </div>
        </header>
      </b-overlay>

      <b-overlay :show="loadingRekap">
        <section v-if="selectedJadwal">
          <!-- Card Rekap -->
          <b-row class="justify-content-center align-items-center mb-1">
            <b-col sm="12" md="4" lg="3">
              <b-card>
                <h5 class="text-center mb-1"><strong>Ujian Diikuti</strong></h5>
                <h1 class="text-center text-primary">
                  <strong>{{ rekap.ujian_diikuti }}</strong>
                </h1>
              </b-card>
            </b-col>
            <b-col sm="12" md="4" lg="3">
              <b-card>
                <h5 class="text-center mb-1">
                  <strong>Ujian Dikerjakan</strong>
                </h5>
                <h1 class="text-center text-primary">
                  <strong>{{ rekap.ujian_dikerjakan }}</strong>
                </h1>
              </b-card>
            </b-col>
            <b-col sm="12" md="4" lg="3">
              <b-card>
                <h5 class="text-center mb-1">
                  <strong>Nilai Rata-Rata</strong>
                </h5>
                <h1 class="text-center text-primary">
                  <strong>{{ rekap.nilai_rata }}</strong>
                </h1>
              </b-card>
            </b-col>
          </b-row>
          <!-- / -->
          <!-- Raport -->
          <b-card
            v-if="headers.length > 0 && raports.length > 0"
            style="overflow: auto"
          >
            <table class="table table-bordered table-responsive-md">
              <thead>
                <tr>
                  <th class="text-center" v-for="(head, i) in headers" :key="i">
                    {{ head }}
                  </th>
                  <th class="text-center" style="width: 8%">
                    Hasil Rasionalisasi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-center"
                    v-for="(raport, i) in raports"
                    :key="i"
                  >
                    <strong>{{ raport.nilai }}</strong>
                  </td>
                  <td class="text-center" style="width: 8%">
                    <feather-icon
                      @click="viewRasionalisasi"
                      icon="EyeIcon"
                      size="24"
                      class="text-primary cursor-pointer"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </b-card>
          <!-- / -->
        </section>

        <b-alert v-else show variant="warning" class="p-1">
          <p class="text-center">
            Silahkan pilih jadwal untuk melihat hasil raport anda.
          </p>
        </b-alert>
      </b-overlay>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BAlert,
  BCard,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BTable,
  BTabs,
  BTab,
  BButton,
  BFormSelect,
} from "bootstrap-vue";

import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import Blank from "./components/Blank";
export default {
  data() {
    return {
      loading: false,
      loadingJadwal: false,
      selectedJadwal: false,
      selectedPaket: null,
      jadwalOptions: [
        {
          text: "-- Pilih Jadwal --",
          value: false,
        },
      ],
      loadingRekap: false,
      categoryPaketId: null,
      raports: [],
      headers: [],
      dataPaket: [],
      rekap: {
        ujian_diikuti: 0,
        ujian_dikerjakan: 0,
        nilai_rata: 0,
      },
      paketCategories: [],
    };
  },
  components: {
    BOverlay,
    BAlert,
    BFormSelect,
    BCard,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BTabs,
    BTab,
    BButton,
    StatisticCardHorizontal,
    Blank,
  },
  watch: {
    selectedPaket(paket) {
      if (paket) {
        // get jadwal per paket
        const paketId = paket.id;
        this.getJadwalPaket(paketId);
        this.headers = [];
        this.raports = [];
        this.rekap.ujian_diikuti = 0;
        this.rekap.ujian_dikerjakan = 0;
        this.rekap.nilai_rata = 0;
      } else {
        this.selectedJadwal = false;
        this.jadwalOptions = [
          {
            text: "-- Pilih Jadwal --",
            value: false,
          },
        ];
      }
    },
    selectedJadwal(jadwalId) {
      if (jadwalId) {
        this.getDataRekap(jadwalId);
      }
    },
  },
  methods: {
    viewRasionalisasi() {
      this.$store.commit("paketTryout/SET_DETAIL", this.selectedPaket);
      this.$router.push({
        name: "hasil-rasionalisasi",
        query: { p: this.selectedPaket?.id, j: this.selectedJadwal },
      });
    },
    changeFilterPaket(paket) {
      this.dataPaket = this.dataPaket.map((paket) => {
        paket.active = false;
        return paket;
      });

      const index = this.dataPaket.findIndex((item) => item.id == paket.id);
      if (index > -1) {
        this.dataPaket[index].active = true;
        this.selectedPaket = this.dataPaket[index];
      } else {
        this.selectedPaket = null;
      }
    },
    reloadPage() {
      window.location.reload();
    },
    setPaket(item) {
      this.categoryPaketId = item.id;
    },
    getDataPaket() {
      this.loading = true;
      let params = { active: 1 };
      this.$store
        .dispatch("tryout/indexMyPaket", params)
        .then((res) => {
          this.loading = false;
          // add prop active for stylinmg
          const myPaket = res.data.data.map((paket) => {
            paket.active = false;
            return paket;
          });

          if (myPaket.length > 0) {
            myPaket[0].active = true;
            this.selectedPaket = myPaket[0];
          }

          this.dataPaket = myPaket;
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    getDataRekap(jadwalId) {
      this.loadingRekap = true;
      let params = {
        jadwal_id: jadwalId,
      };
      this.$store
        .dispatch("raport/rekap", params)
        .then((res) => {
          this.rekap = res.data;
          this.getDataRaport(jadwalId);
        })
        .catch((err) => {
          this.loadingRekap = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getDataRaport(jadwalId) {
      let params = {
        jadwal_id: jadwalId,
      };
      this.$store
        .dispatch("raport/list", params)
        .then((res) => {
          this.loadingRekap = false;
          // this.raports = res.data?.data;
          const raports = res.data?.data;

          // create header
          const ujianShortname = raports[0].scores.map(
            (ujian) => ujian.ujian_shortname
          );
          const tableHeader = ujianShortname;
          const tableData = raports[0].scores;
          this.headers = tableHeader;
          this.raports = tableData;
        })
        .catch((err) => {
          this.loadingRekap = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    async getJadwalPaket(paketId) {
      try {
        this.loadingJadwal = true;
        this.jadwalOptions = [
          {
            text: "-- Pilih Jadwal --",
            value: false,
          },
        ];
        let params = {
          paket_id: paketId,
          user_id: this.user.id,
          is_complete: 1,
        };

        const response = await this.$store.dispatch(
          "paketTryout/jadwalSaya",
          params
        );
        let result = response.data?.data;
        let jadwal = Object.assign([], result);
        jadwal = jadwal.map((item) => {
          item.value = item.id;
          item.text = item.name;
          return item;
        });
        this.jadwalOptions.push(...jadwal);
        this.loadingJadwal = false;
      } catch (e) {
        this.loadingJadwal = false;
        this.displayError(e);
        return false;
      }
    },
  },
  async created() {
    this.getDataPaket();
  },
};
</script>

<style></style>
